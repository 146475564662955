import React from "react";
import "./AboutBanner.css";
import tech from "../../images/technology.png";
import team from "../../images/link.png";
import future from "../../images/long-term.png";
import client from "../../images/rating.png";

function AboutBanner() {
  return (
    <section className="about-banner">
      <div className="container">
        <p id="title__mini">About Us</p>
        <h1>
          Get to know us, <br /> your trusted tech innovators
        </h1>
        <p>
          We're a dedicated team committed to delivering innovative technology
          solutions that revolutionize businesses
        </p>

        <div className="about-banner__cards">
          <div className="about-banner__card">
            <div className="image">
              <img src={tech} alt="" />
            </div>
            <div className="title-sm">
              <p>Tech Driven</p>
            </div>
            <div className="text">
              Our commitment to innovation shapes our approach to solving your
              tech challenges.
            </div>
          </div>

          <div className="about-banner__card">
            <div className="image">
              <img src={team} alt="" />
            </div>
            <div className="title-sm">
              <p>Experienced Team</p>
            </div>
            <div className="text">
              Our team's extensive tech expertise ensures the delivery of
              top-tier solutions.
            </div>
          </div>

          <div className="about-banner__card">
            <div className="image">
              <img src={client} alt="" />
            </div>
            <div className="title-sm">
              <p>Client Centric</p>
            </div>
            <div className="text">
              We put our clients' needs first, focusing on creating impactful,
              personalized solutions.
            </div>
          </div>

          <div className="about-banner__card">
            <div className="image">
              <img src={future} alt="" />
            </div>
            <div className="title-sm">
              <p>Future Focused</p>
            </div>
            <div className="text">
              We leverage cutting-edge tech to deliver future-ready solutions to
              drive your growth.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutBanner;
