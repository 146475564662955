import React, { useState, useEffect } from "react";
import logo from "../../images/logo-transparent.png";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import * as faIcons from "react-icons/fa";
import * as aiIcons from "react-icons/ai";
import "./Header.css";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    const scrollY = window.scrollY;
    if (scrollY > 180) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }

  const headerClassName = `main-header sticky-header ${
    scrolling ? "active" : ""
  }`;

  // sidebar menu
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const showMobileSidebar = () => setMobileSidebar(!mobileSidebar);

  return (
    <>
      <header className="main-header">
        <div className="top-bar--mini">
          <div className="top-bar__contact-box">
            <div className="icon-bg">
              <a href="tel:419 819 5807">
                <faIcons.FaPhone />
                &nbsp; 4198195807
              </a>
            </div>

            <div className="icon-bg">
              <a href="mailto:yshrestha@datnst.com">
                <faIcons.FaEnvelope />
                &nbsp; yshrestha@datnst.com
              </a>
            </div>

            <div className="icon-bg">
              <a href="#!">
                <faIcons.FaLocationArrow />
                &nbsp; 1396 Buckmeyer Drive, Marysville, OH 43040
              </a>
            </div>
          </div>
        </div>
        <div className="top-bar">
          <div className="container">
            <div className="top-bar__logo">
              <Link to="/" className="header-logo">
                <img src={logo} alt="Data Nest Logo" />
              </Link>
            </div>
            <div className="top-bar__links">
              <div className="header-link">
                <Link to="/">Home</Link>
              </div>
              <p>/</p>
              <div className="header-link">
                <Link to="/about-us">About</Link>
              </div>
              <p>/</p>
              <div className="header-link">
                <Link to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <header className="mobile-header">
        <div className="container">
          <div className="menu-bars" onClick={showMobileSidebar}>
            <IconContext.Provider value={{ size: "1.5em", color: "#0d3c6b" }}>
              <faIcons.FaBars />
            </IconContext.Provider>
          </div>

          <Link to="/" className="header-logo">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </header>

      {mobileSidebar && (
        <div className="cart-overlay" onClick={showMobileSidebar}></div>
      )}
      <nav
        className={mobileSidebar ? "mobile-sidebar active" : "mobile-sidebar"}
      >
        <div className="container-fluid">
          <div className="mobileSidebar--header">
            <Link to="/" onClick={showMobileSidebar}>
              <img src={logo} alt="" />
            </Link>
            <button
              onClick={showMobileSidebar}
              className="mobileSidebar--close-btn"
            >
              <IconContext.Provider value={{ size: "1.8em", color: "#0d3c6b" }}>
                <aiIcons.AiOutlineClose />
              </IconContext.Provider>
            </button>
          </div>

          <div className="mobileSidebar--menu_section">
            <div className="mobileSidebar--menu_items">
              <div className="mobileSidebar--menu_item">
                <Link to="/" onClick={showMobileSidebar}>
                  Home
                </Link>
              </div>
              <div className="mobileSidebar--menu_item">
                <Link to="/about-us" onClick={showMobileSidebar}>
                  About
                </Link>
              </div>
              <div className="mobileSidebar--menu_item">
                <Link to="/contact-us" onClick={showMobileSidebar}>
                  Contact Us
                </Link>
              </div>
              <div className="mobileSidebar--menu_item">
                <a href="tel:419 819 5807">
                  <faIcons.FaPhone /> &nbsp; 419 819 5807
                </a>
              </div>
              <div className="mobileSidebar--menu_item">
                <a href="mailto:yshrestha@datnst.com">
                  <faIcons.FaEnvelope /> &nbsp; yshrestha@datnst.com
                </a>
              </div>
              <div className="mobileSidebar--menu_item">
                <a href="#!">
                  <faIcons.FaLocationArrow /> &nbsp; 1396 Buckmeyer Drive,
                  Marysville, OH 43040
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <header className={headerClassName}>
        <div className="top-bar--mini">
          <div className="top-bar__contact-box">
            <div className="icon-bg">
              <a href="tel:419 819 5807">
                <faIcons.FaPhone />
                &nbsp; 4198195807
              </a>
            </div>

            <div className="icon-bg">
              <a href="mailto:yshrestha@datnst.com">
                <faIcons.FaEnvelope />
                &nbsp; yshrestha@datnst.com
              </a>
            </div>

            <div className="icon-bg">
              <a href="#!">
                <faIcons.FaLocationArrow />
                &nbsp; 1396 Buckmeyer Drive, Marysville, OH 43040
              </a>
            </div>
          </div>
        </div>
        <div className="top-bar">
          <div className="container">
            <div className="top-bar__logo">
              <Link to="/" className="header-logo">
                <img src={logo} alt="Data Nest Logo" />
              </Link>
            </div>
            <div className="top-bar__links">
              <div className="header-link">
                <Link to="/">Home</Link>
              </div>
              <p>/</p>
              <div className="header-link">
                <Link to="/about-us">About</Link>
              </div>
              <p>/</p>
              <div className="header-link">
                <Link to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
