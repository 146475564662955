import React, { useRef } from "react";
import consultation from "../../images/slider3.jpg";
import "./Consultation.css";
import emailjs from "@emailjs/browser";

function Consultation() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9dmx3zh",
        "template_pncnczi",
        form.current,
        "Q4PRr6kqkWkzyAnyb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section className="consultation-section" id="consultation-section">
      <div className="container">
        <div className="form-image">
          <img src={consultation} alt="" />
        </div>
        <div className="form-container">
          <p id="title-sm">Free Consultation</p>
          <form action="" ref={form} onSubmit={sendEmail}>
            <div className="input-group">
              <input type="text" name="fname" placeholder="Full Name" />
            </div>
            <div className="input-group">
              <input type="text" name="email" placeholder="Email" />
            </div>
            <div className="input-group">
              <input type="text" name="number" placeholder="Phone Number" />
            </div>
            <div className="input-group">
              {/* <select name="" id="">
                <option value="" disabled selected>
                  Service You're Looking For?
                </option>
                <option value="">Business Services</option>
                <option value="">Financial Services</option>
                <option value="">Quality Resourcing</option>
              </select> */}
              <input
                type="text"
                name="service"
                placeholder="Service you're looking for?"
              />
            </div>
            <div className="input-group span-2">
              <textarea
                name="message"
                id=""
                cols="50"
                rows="4"
                placeholder="Message"
              ></textarea>
            </div>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </section>
  );
}

export default Consultation;
