import React from "react";
import AboutBanner from "../components/AboutComponents/AboutBanner";
import AboutDesc from "../components/AboutComponents/AboutDesc";
import Expertise from "../components/AboutComponents/Expertise";
import Consultation from "../components/HomeComponents/Consultation";

function AboutScreen() {
  return (
    <>
      <AboutBanner />
      <AboutDesc />
      <Expertise />
      <Consultation />
    </>
  );
}

export default AboutScreen;
