import React from "react";
import "./Expertise.css";
import { Link } from "react-router-dom";
import expertise from "../../images/expertise.png";
import solution from "../../images/solution.png";
import innovation from "../../images/innovation.png";
import client from "../../images/client.png";

function Expertise() {
  return (
    <section className="expertise-section">
      <div className="container">
        <div className="expertise-title">
          <div className="expertise-title__inner">
            <p id="title__mini">Our Mission</p>
            <h1>Harnessing Expertise to Drive Your Business Forward</h1>
            <p>
              We're a team of business and technology enthusiasts committed to
              delivering exceptional solutions that align with your business
              goals.
            </p>
            <div className="expertise-btns">
              <div className="primary-btn__alt">
                <a href="#consultation-section">Free Consultation</a>
              </div>
            </div>
          </div>
        </div>
        <div className="expertise-scroller">
          <div className="expertise-scroller__inner">
            <div className="expertise--card">
              <div className="card--img">
                <img src={expertise} alt="" />
              </div>
              <p id="title-sm">Expertise</p>
              <p>
                Our team of seasoned consultants brings deep industry knowledge
                and a diverse skill set to every project.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={solution} alt="" />
              </div>
              <p id="title-sm">Customized Solutions</p>
              <p>
                We understand that every business is unique, which is why we
                tailor our solutions to meet your specific needs and challenges.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={innovation} alt="" />
              </div>
              <p id="title-sm">Innovation</p>
              <p>
                We stay at the forefront of industry trends and leverage
                cutting-edge tools and technologies to drive innovation in your
                organization.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={client} alt="" />
              </div>
              <p id="title-sm">Client-Centric Approach</p>
              <p>
                Your success is our priority. We are dedicated to building
                long-term relationships and providing ongoing support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Expertise;
