import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import HomeScreen from "./Screen/HomeScreen";
import Footer from "./components/Footer/Footer";
import ContactScreen from "./Screen/ContactScreen";
import AboutScreen from "./Screen/AboutScreen";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <section className="canvas">
      <div className="glassmorphism"></div>
      <div className="main">
        <BrowserRouter>
          <Header />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/contact-us" element={<ContactScreen />} />
            <Route path="/about-us" element={<AboutScreen />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </section>
  );
}

export default App;
