import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ContactScreen.css";
import contact from "../images/contact.jpg";
import contact2 from "../images/contact2.png";
import office from "../images/office.png";
import { Link } from "react-router-dom";
import * as faIcons from "react-icons/fa6";

function ContactScreen() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_deuulld",
        "template_bgoztwg",
        form.current,
        "Q4PRr6kqkWkzyAnyb"
      )
      .then(
        (result) => {
          alert(
            "Your message has been sent successfullt. We will get back to you soon."
          );
        },
        (error) => {
          alert("Something went wrong. Please try again later");
        }
      );
  };

  return (
    <section className="contact-screen">
      <div className="container">
        <div className="banner">
          <div className="banner-image">
            <img src={contact} alt="" />
          </div>
          <div className="banner-text">
            <div className="banner-breadcrumb">
              <Link to="/">Home</Link> <p>/</p>
              <Link to="/contact-us">Contact</Link>
            </div>
            <div className="banner-desc">
              <h1>Contact Us</h1>
              <p>
                Ready to take your business to the next level? Contact us today
                to discuss how our consultancy services can <br />
                benefit your organization. We look forward to partnering with
                you on your journey to success.{" "}
              </p>
              <div className="banner__scroll-links">
                <a href="#contact-grid">
                  Office{" "}
                  <p>
                    <faIcons.FaArrowRight />
                  </p>
                </a>
                <a href="#contact-form">
                  Fill the form
                  <p>
                    <faIcons.FaArrowRight />
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-grid" id="contact-grid">
          <div className="col">
            <div className="col__img">
              <img src={contact2} alt="" />
            </div>
            <div className="col__contact-info">
              <span className="contact-details">
                <h2>Support</h2>
                <div className="mail-phone">
                  <a href="tel:419 819 5807">
                    <faIcons.FaPhone />
                    <p>419 819 5807</p>
                  </a>
                  <a href="mailto:yshrestha@datnst.com">
                    <faIcons.FaEnvelope />
                    <p>yshrestha@datnst.com</p>
                  </a>
                </div>
              </span>
            </div>
          </div>
          <div className="col">
            <div className="col__img">
              <img src={office} alt="" />
            </div>
            <div className="col__contact-info">
              <span className="location">
                <h2>Office</h2>
                <span>1396 Buckmeyer Drive, Marysville, OH 43040</span>
              </span>
              <a
                href="https://maps.app.goo.gl/TZwSiTifZBdqw9xq7"
                target="_blank"
                rel="noreferrer"
              >
                <faIcons.FaLocationDot />
                View on Maps
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form" id="contact-form">
          <h2>Fill the form</h2>
          <form action="" ref={form} onSubmit={sendEmail}>
            <input type="text" name="fname" id="" placeholder="Full Name" />
            <input type="email" name="email" id="" placeholder="Email" />
            <input type="text" name="number" id="" placeholder="Phone Number" />
            <input type="text" name="subject" placeholder="Subject" />
            <textarea
              name="message"
              id=""
              cols="30"
              rows="3"
              placeholder="Message"
            ></textarea>
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
      <div className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3046.775872352928!2d-83.36796942462006!3d40.21404827147295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8838c43bb2f86947%3A0x63c154a8e8a2f779!2s1396%20Buckmeyer%20Dr%2C%20Marysville%2C%20OH%2043040%2C%20USA!5e0!3m2!1sen!2snp!4v1695131011300!5m2!1sen!2snp"
          style={{ location: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="location"
        ></iframe>
        <div className="map-details">
          <p>View on Maps</p>
          <h2>Find us and visit our innovation hub</h2>
          <div className="primary-btn">
            <a
              href="https://maps.app.goo.gl/TZwSiTifZBdqw9xq7"
              target="_blank"
              rel="noreferrer"
            >
              View Location
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactScreen;
