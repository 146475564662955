import React from "react";
import "./AboutDesc.css";

function AboutDesc() {
  return (
    <section className="aboutdesc-section">
      <div className="container">
        <h1>
          With our complete tech services & solutions, take your business new
          digital era.
        </h1>
        <div className="about--description">
          <div className="left-container">
            <div className="divider"></div>
            <p>
              At DataNest, we are dedicated to driving your success through
              expert strategic guidance and tailored solutions
            </p>
            <p>
              With a wealth of experience and a commitment to excellence, we
              partner with businesses and organizations of all sizes to achieve
              their goals and overcome challenges.
            </p>
            <p>
              Our consultancy services are designed to empower you with the
              knowledge, strategies, and tools needed to thrive in today's
              dynamic business landscape.
            </p>
          </div>
          <div className="right-container">
            <div className="desc-points">
              <p id="title__mini">Vision</p>
              <p>Paving the way for a digital future</p>
            </div>
            <div className="desc-points">
              <p id="title__mini">Values</p>
              <p>Driving innovation through collaboration</p>
            </div>
            <div className="desc-points">
              <p id="title__mini">Create</p>
              <p>How we transform businesses with technology</p>
            </div>
            <div className="desc-points">
              <p id="title__mini">Future</p>
              <p>Keeping you ahead in the digital age</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutDesc;
