import React from "react";
import "./LandingSection.css";
import HeroSlider from "../HeroSlider/HeroSlider";
import { useSpring, animated } from "react-spring";

function LandingSection({ offset, speed }) {
  const styles = useSpring({
    transform: `translateY(${offset * speed}px)`,
    from: { transform: "translateY(-0px)" },
  });
  return (
    <animated.section className="landing-section parallax-item" style={styles}>
      <div className="container">
        <HeroSlider />
      </div>
    </animated.section>
  );
}

export default LandingSection;
