import React, { useEffect, useState } from "react";
import "./HeroSlider.css";
import slide1 from "../../images/slider-img.jpg";
import slide2 from "../../images/slider2.jpg";
import slide3 from "../../images/slider3.jpg";
import { useSpring, animated } from "react-spring";

function HeroSlider({ offset, speed }) {
  const styles = useSpring({
    transform: `translateY(${offset * speed}px)`,
    from: { transform: "translateY(0px)" },
  });

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    slide1,
    slide2,
    slide3,
    // Add more image URLs as needed
  ];

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 8000); // Change slide every 5 seconds

    return () => {
      clearInterval(autoplayInterval);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMouseX(e.clientX);
      setMouseY(e.clientY);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const parallaxValueX = (mouseX / window.innerWidth) * 10; // Adjust the factor as needed
  const parallaxValueY = (mouseY / window.innerHeight) * 10;

  const imageStyles = {
    transform: `translate(${parallaxValueX}px, ${parallaxValueY}px)`,
  };

  return (
    <animated.div className="hero-slider parallax-item" style={styles}>
      {slides.map((slide, index) => (
        <>
          <div
            key={index}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={slide} alt={`Slide ${index}`} style={imageStyles} />
          </div>
        </>
      ))}
      <div className="slide-text">
        <h1>
          Empowering Success through <br />
          strategic consulting
        </h1>
        <p>
          drive your success through expert strategic guidance and tailored
          solutions
        </p>
      </div>
    </animated.div>
  );
}

export default HeroSlider;
