import React from "react";
import "./OurMission.css";

function OurMission() {
  return (
    <section className="our-mission">
      <div className="container">
        <div className="our-mission__inner">
          <p id="title__mini">Our Mission</p>
          <h1>
            Catalyzing Your Business's Evolution Toward Sustainable Success
          </h1>
          <p>
            Our mission is to be your trusted partner in business growth and
            transformation. We work collaboratively with our clients to identify
            opportunities, navigate complexities, and make informed decisions
            that lead to sustainable success. We are committed to delivering
            measurable results and helping you reach your full potential.
          </p>
        </div>
      </div>
    </section>
  );
}

export default OurMission;
