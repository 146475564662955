import React from "react";
import "../../components/AboutComponents/Expertise.css";
import { Link } from "react-router-dom";
import strategic from "../../images/analysis(1).png";
import management from "../../images/manager.png";
import research from "../../images/research.png";
import financialResearch from "../../images/money(1).png";
import changeManagement from "../../images/change-management.png";
import training from "../../images/career(1).png";
import technology from "../../images/technology(2).png";

function Services() {
  return (
    <section className="expertise-section">
      <div className="container">
        <div className="expertise-title">
          <div className="expertise-title__inner">
            <p id="title__mini">Our Services</p>
            <h1>
              Harnessing the power of technology to solve business challenges.
            </h1>
            <p>
              We're a team of business and technology enthusiasts committed to
              delivering exceptional solutions that align with your business
              goals.
            </p>
            <div className="expertise-btns">
              <div className="primary-btn">
                <Link to="/about-us">About Us</Link>
              </div>

              <div className="primary-btn__alt">
                <a href="#consultation-section">Free Consultation</a>
              </div>
            </div>
          </div>
        </div>
        <div className="expertise-scroller">
          <div className="expertise-scroller__inner">
            <div className="expertise--card">
              <div className="card--img">
                <img src={strategic} alt="" />
              </div>
              <p id="title-sm">Strategic Planning</p>
              <p>
                Develop clear, actionable strategies that align with your vision
                and objectives.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={management} alt="" />
              </div>
              <p id="title-sm">Management Consulting</p>
              <p>
                Improve operations, enhance efficiency, and optimize processes.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={research} alt="" />
              </div>
              <p id="title-sm">Market Research </p>
              <p>
                Gain valuable insights into your target market and competition.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={financialResearch} alt="" />
              </div>
              <p id="title-sm">Financial Analysis</p>
              <p>
                Make data-driven financial decisions and secure a strong
                financial future.
              </p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={changeManagement} alt="" />
              </div>
              <p id="title-sm">Change Management</p>
              <p>Effectively navigate organizational change and transition.</p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={training} alt="" />
              </div>
              <p id="title-sm">Training and Development</p>
              <p>Equip your team with the skills and knowledge to excel.</p>
            </div>
            <div className="expertise--card">
              <div className="card--img">
                <img src={technology} alt="" />
              </div>
              <p id="title-sm">Technology Integration</p>
              <p>
                Leverage technology to streamline operations and drive growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
