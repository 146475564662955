import React, { Component } from "react";
import "./Odometer.css";

class Odometer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      inViewport: false, // Track if the element is in the viewport
    };
    this.odometerRef = React.createRef();
  }

  componentDidMount() {
    // Create an Intersection Observer to watch for the element entering the viewport
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin around the viewport
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.odometerRef.current);
  }

  componentWillUnmount() {
    // Disconnect the Intersection Observer when the component unmounts
    this.observer.disconnect();
  }

  // Callback for when the element intersects with the viewport
  handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      // When the element is in the viewport, start the animation
      this.animateValue(0, this.props.finalValue, this.props.duration);
      this.setState({ inViewport: true });
    } else {
      this.setState({ inViewport: false });
    }
  };

  animateValue = (start, end, duration) => {
    const range = end - start;
    const stepTime = Math.abs(Math.floor(duration / range));
    let currentValue = start;

    const timer = setInterval(() => {
      currentValue += 1;
      this.setState({ value: currentValue });
      if (currentValue >= end) {
        clearInterval(timer);
      }
    }, stepTime);
  };

  render() {
    return (
      <div
        ref={this.odometerRef}
        className={`odometer ${this.state.inViewport ? "in-viewport" : ""}`}
      >
        {this.state.value}
      </div>
    );
  }
}

export default Odometer;
