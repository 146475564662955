import React from "react";
import "./Odometer.css";
import Odometer from "./Odometer";

function OdometerSection() {
  return (
    <section className="odometer-section">
      <div className="container">
        <p id="title__mini">Our Success</p>
        <h1>Our Success in Numbers</h1>
        <div className="odometer-grid">
          <div className="odometer-card">
            <span>
              <Odometer finalValue={2} duration={2000} /> <p>+</p>
            </span>
            <p>Years of Excellence</p>
          </div>
          <div className="odometer-card">
            <span>
              <Odometer finalValue={100} duration={2000} /> <p>%</p>
            </span>
            <p>Client Satisfaction</p>
          </div>
          <div className="odometer-card">
            <span>
              <Odometer finalValue={2} duration={2000} /> <p>k</p>
            </span>
            <p>Recruitments</p>
          </div>
          <div className="odometer-card">
            <span>
              <Odometer finalValue={24} duration={2000} /> <p></p>
            </span>
            <p>Consultants</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OdometerSection;
