import React from "react";
import logo from "../../images/logo-transparent.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import * as faIcons from "react-icons/fa";

function Footer() {
  return (
    // <footer>
    //   <div className="container">
    //     <div className="footer-logo">
    //       <img src={logo} alt="" />
    //     </div>
    //     <div className="footer-menu">
    //       <div className="footer-link">
    //         <Link to="/">Home</Link>
    //       </div>
    //       <div className="footer-link">
    //         <Link to="/about-us">About Us</Link>
    //       </div>
    //       <div className="footer-link">
    //         <Link to="/contact-us">Contact</Link>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="newsletter">
    //         <p>Subscribe to our newsletter</p>
    //         <form action="">
    //           <input type="email" placeholder="Email Address" />
    //           <input type="submit" name="" id="" value="Subscribe" />
    //         </form>
    //       </div>
    //       <div className="social-links">
    //         <div className="social-link">
    //           <a href="#!">
    //             <faIcons.FaFacebookF />
    //           </a>
    //         </div>
    //         <div className="social-link">
    //           <a href="#!">
    //             <faIcons.FaInstagram />
    //           </a>
    //         </div>
    //         <div className="social-link">
    //           <a href="#!">
    //             <faIcons.FaLinkedin />
    //           </a>
    //         </div>
    //         <div className="social-link">
    //           <div className="tooltip">
    //             <p>4198195807</p>
    //           </div>
    //           <a href="tel:419 819 5807">
    //             <faIcons.FaPhone />
    //           </a>
    //         </div>
    //         <div className="social-link">
    //           <div className="tooltip">
    //             <p>1396 Buckmeyer Drive, Marysville, OH 43040</p>
    //           </div>
    //           <a href="#!">
    //             <faIcons.FaLocationArrow />
    //           </a>
    //         </div>
    //         <div className="social-link">
    //           <div className="tooltip">
    //             <p>datanest@info.com.np</p>
    //           </div>
    //           <a href="#!">
    //             <faIcons.FaEnvelope />
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="footer-widget">
    //         <div className="footer-widget__link">
    //           <a href="#!">Sitemap</a>
    //           <a href="#!">Privacy Policy</a>
    //           <a href="#!">Terms of use</a>
    //         </div>
    //       </div>
    //       <div className="footer-widget">
    //         <div className="footer-widget__copy">
    //           <p>Copyright &copy; Datanest - 2023</p>
    //           <p>
    //             Contact{" "}
    //             <a
    //               href="https://www.instagram.com/"
    //               rel="noreferrer"
    //               target="_blank"
    //             >
    //               Developer
    //             </a>
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </footer>

    <footer>
      <div className="container">
        <div className="col">
          <h2>About Us</h2>
          <p>
            At DataNest, we are dedicated to driving your success through expert
            strategic guidance and tailored solutions.
          </p>
          <div className="social-links">
            <div className="social-link">
              <a href="#!">
                <faIcons.FaFacebookF />
              </a>
            </div>
            <div className="social-link">
              <a href="#!">
                <faIcons.FaInstagram />
              </a>
            </div>
            <div className="social-link">
              <a href="#!">
                <faIcons.FaLinkedin />
              </a>
            </div>
          </div>
        </div>

        <div className="col">
          <h2>Quick Links</h2>

          <div className="footer-links second-col">
            <div className="footer-link">
              <Link to="/">Home</Link>
            </div>
            <div className="footer-link">
              <Link to="/about-us">About Us</Link>
            </div>
            <div className="footer-link">
              <Link to="/contact-us">Contact</Link>
            </div>
          </div>
        </div>

        <div className="col">
          <h2>Contact Us</h2>

          <div className="footer-links">
            <div className="footer-link">
              <a href="tel:419 819 5807">
                <faIcons.FaPhone /> &nbsp;&nbsp;419 819 5807
              </a>
            </div>
            <div className="footer-link">
              <p>
                <faIcons.FaLocationArrow />
                &nbsp;&nbsp; 1396 Buckmeyer Drive, Marysville, OH 43040
              </p>
            </div>
            <div className="footer-link">
              <a href="mailto:yshrestha@datnst.com">
                <faIcons.FaEnvelope />
                &nbsp;&nbsp; yshrestha@datnst.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <p>All rights belong to Datanest &copy; 2023</p>
        <p>
          Contact <a href="https://www.instagram.com/">Developer</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
