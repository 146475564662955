import React, { useState, useEffect } from "react";
import LandingSection from "../components/HomeComponents/LandingSection";
import OurMission from "../components/HomeComponents/OurMission";
import Consultation from "../components/HomeComponents/Consultation";
import OdometerSection from "../components/HomeComponents/OdometerSection";
import Services from "../components/HomeComponents/Services";

function HomeScreen() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <LandingSection />
      <OurMission />
      <Services />
      <OdometerSection />
      <Consultation />
    </>
  );
}

export default HomeScreen;
